import React, { useState } from 'react';
import axios from 'axios';
import { Line, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, LineElement, PointElement } from 'chart.js';
import './App.css';
import logo from './RAUSCH-Tech-Logo-White.png'; // Importieren Sie das Logo

// Registrierung der Chart-Komponenten
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement
);

// Statische Werte für die Auswahlfelder
const buildingFunctions = [
  "residential",
  "public_building",
  "retirement_home",
  "dormitory",
  "other"
];

const buildingTypes = [
  "apartment_building",
  "semi_detached_building",
  "row_house",
  "detached_building",
  "other"
];

const heatGenerations = [
  "teleheating",
  "solar",
  "boiler",
  "heatpump",
  "other"
];

const energySources = [
  "Biodiesel",
  "Bioethanol",
  "Biogas",
  "BiomassWood",
  "BrownCoal",
  "LandfillGas",
  "NaturalGas",
  "LiquefiedPetroleumGas",
  "LightFuelOil",
  "HeavyFuelOil",
  "SewageGas",
  "SewageSludge",
  "DistrictHeating",
  "Pellets",
  "ProcessSteam",
  "CrudeGasoline",
  "HardCoal",
  "ElectricEfficiencyMeasure",
  "ElectricFuelSwitch",
  "ElectricRenewableSwitch",
  "Hydrogen",
  "RenewableHydrogen"
];

const heatingSurfaces = [
  "radiator",
  "floor",
  "other"
];

const waterHeatingTypes = [
  "central",
  "decentral"
];

function App() {
  const [formData, setFormData] = useState({
    zipCode: '97074',
    city: 'Würzburg',
    street: 'Leightonstrasse',
    houseNumber: '3',
    buildingFunction: buildingFunctions[1],
    buildingType: buildingTypes[4],
    yearOfConstruction: 2000,
    heatedArea: 800,
    livingUnits: 10,
    reconFacades: 2010,
    reconRoof: 2010,
    reconWindows: 2010,
    heatGeneration: heatGenerations[0],
    energySource: energySources[6],
    regenerativeEnergySource: false,
    heatGenerationAge: 5,
    heatGenerationPower: 300,
    heatingSurface: heatingSurfaces[0],
    hydraulicBalancing: true,
    waterHeatingType: waterHeatingTypes[0],
    energyConsumptionHeating: {2020: 80000, 2021: 85000, 2022: 79000},
    energySourceContributions: [{ energy_source: energySources[6], proportion: 1.0 }]
  });

  const [energyConsumptionHeating, setEnergyConsumptionHeating] = useState([
    { year: 2020, consumption: 80000 },
    { year: 2021, consumption: 85000 },
    { year: 2022, consumption: 79000 }
  ]);

  const [energySourceContributions, setEnergySourceContributions] = useState([
    { energy_source: energySources[6], proportion: 1.0 }
  ]);

  const [response, setResponse] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleEnergyConsumptionChange = (index, e) => {
    const { name, value } = e.target;
    const newEnergyConsumptionHeating = [...energyConsumptionHeating];
    newEnergyConsumptionHeating[index][name] = value;
    setEnergyConsumptionHeating(newEnergyConsumptionHeating);
  };

  const handleAddYear = () => {
    setEnergyConsumptionHeating([...energyConsumptionHeating, { year: '', consumption: '' }]);
  };

  const handleRemoveYear = (index) => {
    const newEnergyConsumptionHeating = [...energyConsumptionHeating];
    newEnergyConsumptionHeating.splice(index, 1);
    setEnergyConsumptionHeating(newEnergyConsumptionHeating);
  };

  const handleEnergySourceChange = (index, e) => {
    const { name, value } = e.target;
    const newEnergySourceContributions = [...energySourceContributions];
    newEnergySourceContributions[index][name] = value;
    setEnergySourceContributions(newEnergySourceContributions);
  };

  const handleAddEnergySource = () => {
    setEnergySourceContributions([...energySourceContributions, { energy_source: '', proportion: '' }]);
  };

  const handleRemoveEnergySource = (index) => {
    const newEnergySourceContributions = [...energySourceContributions];
    newEnergySourceContributions.splice(index, 1);
    setEnergySourceContributions(newEnergySourceContributions);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const energyConsumptionHeatingObj = energyConsumptionHeating.reduce((acc, cur) => {
      acc[cur.year] = cur.consumption;
      return acc;
    }, {});

    const dataToSend = {
      ...formData,
      energyConsumptionHeating: energyConsumptionHeatingObj,
      energySourceContributions
    };

    try {
      const res = await axios.post('https://atum.io.rausch.se/atum', dataToSend);
      setResponse(res.data);
    } catch (error) {
      console.error('Error submitting the form', error);
    }
  };

  const renderCharts = () => {
    if (!response) return null;
  
    const {
      ratingSpecificInstalledBoilerCapacity,
      heatingCurve,
      energyConsumptionHeating,
      energyConsumptionHeatingCorrected,
      referenceEnergyConsumptionHeating,
      fullLoadHours,
      energySourceContributions,
      heatedAreaConsumption,
      co2Emissions,
      standardOutsideTemperature,  
      specificStandardHeatingLoad 
    } = response;
  
    const energyConsumptionData = {
      labels: Object.keys(energyConsumptionHeating),
      datasets: [
        {
          label: 'Energy Consumption Heating',
          data: Object.values(energyConsumptionHeating),
          borderColor: 'rgba(75, 192, 192, 1)',
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
        },
        {
          label: 'Energy Consumption Heating Corrected',
          data: Object.values(energyConsumptionHeatingCorrected),
          borderColor: 'rgba(153, 102, 255, 1)',
          backgroundColor: 'rgba(153, 102, 255, 0.2)',
        },
        {
          label: 'Heated Area Consumption',
          data: Object.values(heatedAreaConsumption),
          borderColor: 'rgba(255, 159, 64, 1)',
          backgroundColor: 'rgba(255, 159, 64, 0.2)',
        }
      ]
    };
  
    const referenceEnergyData = referenceEnergyConsumptionHeating ? {
      labels: ['Good', 'Moderate', 'Too High'],
      datasets: [
        {
          label: 'Reference Energy Consumption Heating',
          data: [
            referenceEnergyConsumptionHeating?.good || 0,
            referenceEnergyConsumptionHeating?.moderate || 0,
            referenceEnergyConsumptionHeating?.tooHigh || 0
          ],
          backgroundColor: [
            'rgba(75, 192, 192, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(255, 99, 132, 0.2)'
          ],
          borderColor: [
            'rgba(75, 192, 192, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(255, 99, 132, 1)'
          ],
          borderWidth: 1
        }
      ]
    } : null;
  
    return (
      <div className="charts">
        <h2>Energy Consumption</h2>
        <Line data={energyConsumptionData} />
        {referenceEnergyData && (
          <>
            <h2>Reference Energy Consumption Heating</h2>
            <Bar data={referenceEnergyData} />
          </>
        )}
        <h2>Energy Source Contributions</h2>
        <Bar data={{
          labels: energySourceContributions.map(esc => esc.energy_source),
          datasets: [{
            label: 'Energy Source Contributions',
            data: energySourceContributions.map(esc => esc.proportion),
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1
          }]
        }} />
        <h2>CO2 Emissions</h2>
        <p>{co2Emissions} kg</p>
        <h2>Full Load Hours</h2>
        <p>{fullLoadHours} hours</p>
        <h2>Rating Specific Installed Boiler Capacity</h2>
        <p>{ratingSpecificInstalledBoilerCapacity}</p>
        <h2>Heating Curve</h2>
        <p>Base: {heatingCurve?.base}°C</p>
        <p>BaseTa: {heatingCurve?.baseTa}°C</p>
        <p>Summer Mode: {heatingCurve?.summerMode}°C</p>
        <p>Max: {heatingCurve?.max}°C</p>
        <p>MaxTa: {heatingCurve?.maxTa}°C</p>
        <h2>Standard Outside Temperature</h2>
        <p>{standardOutsideTemperature}°C</p>
        <h2>Specific Standard Heating Load</h2>
        <p>{specificStandardHeatingLoad} kW</p>
      </div>
    );
  };
  

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>ATUM (Advanced Thermal Utility Modeling)</h1>
      <p>This is a demo application for the ATUM system by RAUSCH Technology. For more information, please contact us at <a href="https://www.rausch.se/" target="_blank" rel="noopener noreferrer">https://www.rausch.se/</a>.</p>
	  </header>
      <main className="container">
        <form className="form-container" onSubmit={handleSubmit}>
          <section className="form-section">
            <h2>Address Information</h2>
            <div className="form-group">
              <label>Zip Code:</label>
              <input type="text" name="zipCode" value={formData.zipCode} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>City:</label>
              <input type="text" name="city" value={formData.city} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Street:</label>
              <input type="text" name="street" value={formData.street} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>House Number:</label>
              <input type="text" name="houseNumber" value={formData.houseNumber} onChange={handleChange} />
            </div>
          </section>
          <section className="form-section">
            <h2>Building Details</h2>
            <div className="form-group">
              <label>Building Function:</label>
              <select name="buildingFunction" value={formData.buildingFunction} onChange={handleChange}>
                {buildingFunctions.map((functionType) => (
                  <option key={functionType} value={functionType}>{functionType}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Building Type:</label>
              <select name="buildingType" value={formData.buildingType} onChange={handleChange}>
                {buildingTypes.map((type) => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Year of Construction:</label>
              <input type="number" name="yearOfConstruction" value={formData.yearOfConstruction} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Heated Area:</label>
              <input type="number" name="heatedArea" value={formData.heatedArea} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Living Units:</label>
              <input type="number" name="livingUnits" value={formData.livingUnits} onChange={handleChange} />
            </div>
          </section>
          <section className="form-section">
            <h2>Heating System Details</h2>
            <div className="form-group">
              <label>Heat Generation:</label>
              <select name="heatGeneration" value={formData.heatGeneration} onChange={handleChange}>
                {heatGenerations.map((generation) => (
                  <option key={generation} value={generation}>{generation}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Energy Source:</label>
              <select name="energySource" value={formData.energySource} onChange={handleChange}>
                {energySources.map((source) => (
                  <option key={source} value={source}>{source}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Heating Surface:</label>
              <select name="heatingSurface" value={formData.heatingSurface} onChange={handleChange}>
                {heatingSurfaces.map((surface) => (
                  <option key={surface} value={surface}>{surface}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Water Heating Type:</label>
              <select name="waterHeatingType" value={formData.waterHeatingType} onChange={handleChange}>
                {waterHeatingTypes.map((type) => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Heat Generation Power:</label>
              <input type="number" name="heatGenerationPower" value={formData.heatGenerationPower} onChange={handleChange} />
            </div>
            <div className="form-group checkbox-group">
              <label>Regenerative Energy Source:</label>
              <input type="checkbox" name="regenerativeEnergySource" checked={formData.regenerativeEnergySource} onChange={(e) => setFormData({
                ...formData,
                regenerativeEnergySource: e.target.checked
              })} />
            </div>
            <div className="form-group checkbox-group">
              <label>Hydraulic Balancing:</label>
              <input type="checkbox" name="hydraulicBalancing" checked={formData.hydraulicBalancing} onChange={(e) => setFormData({
                ...formData,
                hydraulicBalancing: e.target.checked
              })} />
            </div>
          </section>
          <section className="form-section">
            <h2>Energy Consumption Details</h2>
            <div className="form-group">
              <label>Energy Consumption Heating:</label>
              {energyConsumptionHeating.map((ec, index) => (
                <div key={index} className="energy-consumption-group">
                  <input
                    type="number"
                    name="year"
                    value={ec.year}
                    placeholder="Year"
                    onChange={(e) => handleEnergyConsumptionChange(index, e)}
                  />
                  <input
                    type="number"
                    name="consumption"
                    value={ec.consumption}
                    placeholder="Consumption"
                    onChange={(e) => handleEnergyConsumptionChange(index, e)}
                  />
                  <button type="button" onClick={() => handleRemoveYear(index)}>Remove</button>
                </div>
              ))}
              <button type="button" onClick={handleAddYear}>Add Year</button>
            </div>
            <div className="form-group">
              <label>Energy Source Contributions:</label>
              {energySourceContributions.map((esc, index) => (
                <div key={index} className="energy-source-group">
                  <select
                    name="energy_source"
                    value={esc.energy_source}
                    onChange={(e) => handleEnergySourceChange(index, e)}
                  >
                    {energySources.map((source) => (
                      <option key={source} value={source}>{source}</option>
                    ))}
                  </select>
                  <input
                    type="number"
                    name="proportion"
                    value={esc.proportion}
                    placeholder="Proportion"
                    onChange={(e) => handleEnergySourceChange(index, e)}
                  />
                  <button type="button" onClick={() => handleRemoveEnergySource(index)}>Remove</button>
                </div>
              ))}
              <button type="button" onClick={handleAddEnergySource}>Add Energy Source</button>
            </div>
          </section>
          <button type="submit" className="submit-button">Submit</button>
        </form>
        {response && (
          <div className="response-container">
            {renderCharts()}
                <h2>JSON Response</h2>
		<pre>{JSON.stringify(response, null, 2)}</pre>
		<div className="response">
		</div>
        </div>
        )}
      </main>
<footer className="App-footer">
        <a href="https://www.rausch.se/impressum-2/" target="_blank" rel="noopener noreferrer">Impressum</a>
      </footer>
	  </div>
  );
}

export default App;
